<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <div class="row w-100">
      <div class="col">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
          </li>
          <li class="nav-item d-none d-sm-inline-block">
            <a href="#" class="nav-link">
              <!-- {{this.nama_dokter}} -->
            </a>
          </li>
        </ul>
      </div>
      <div class="col">
        <div class="dropdown ml-auto" style="width: fit-content;">
          <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
            <i class="far fa-user-circle"></i>
            {{ my.username }}
          </button>
          <ul class="dropdown-menu" style="left: auto; right: 0;">
            <li class="dropdown-item">
              <button @click="logout()" class="btn text-left">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { getFromLocal, removeFromLocal } from '@/helper/local.helper';

export default {
  data() {
    return {
      my: getFromLocal()
    }
  },

  // computed:{
  //   ...mapGetters({
  //     user: 'auth/user'
  //   }),
  //   nama_dokter(){
  //     return this.user.fullname
  //   }
  // },

  methods: {
    init() {

    },
    logout(){
      removeFromLocal();
      this.$router.push('/');
    },
  },

  mounted() {
    this.init()
  }
}
</script>